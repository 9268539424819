import React, {useContext, useState, useEffect} from 'react'
import cl from './make_bids.module.css'
import { MessageAlertContext,IsUnworkingContext, IsPreparingContext,
 IsRoundContext,  StopwatchValueContext, isPostContext, TotalSumContext, fetchDataContext } from '../../../context/allContext'

const MakeBidsButtonArea = ({changeBorder, isAuto, isCash, setAuto, cashValue, setCashValue, bidding, bid, changeBidding}) => {
    
    const {MessagesAlert, setMesAlert} = useContext(MessageAlertContext)
    const {IsUnworking, setIsUnworking} = useContext(IsUnworkingContext)
    const {isPreparing, setIsPreparing} = useContext(IsPreparingContext)
    const {isRound, setIsRound} = useContext(IsRoundContext)
    const {isPost, setIsPost} = useContext(isPostContext)
    const {stopwatchValue, getStopwatchValue} = useContext(StopwatchValueContext)
    const {TotalSum, setTotalSum} = useContext(TotalSumContext)
    const {fetching, setFetch} = useContext(fetchDataContext)

    const [moneyBid, countBid] = useState('0.20')
    const [inputValue, setValue] = useState('0.20')

    const [disabled, setDisabled] = useState(false)
    const [disabledButton_Auto, setDisabledButton_Auto] = useState(false)
    const [bidMade, makeBid] = useState(false)
    const [nextBid, makeNextBid] = useState(false)

    const [text, setText] = useState('MƏRC')
    const [backColor, setBackColor] = useState('rgb(39, 159, 9)')
    const [awaitRoundDisplay, setAwaitDisplay] = useState('none')
    const [displayP, setDisplayP] = useState('block')

    useEffect(() => {
        if(IsUnworking){
        setDisabled(true)
        setDisabledButton_Auto(true)
        makeNextToRealBid()
        }else {
            
            setDisabledButton_Auto(false)
            if (!IsUnworking && !bidMade && !nextBid){
                setDisabled(false)
            }
        
        }
    }, [IsUnworking])
    
    useEffect(() => {
        if (bidMade && isRound){
            setBackColor('rgb(226, 135, 67)')
            setText('NAĞDLAŞDIRMA')
            setDisplayP('block')
            setAwaitDisplay('none')
            changeBorder('1px solid #e28743')    
        }
    }, [isRound])

    useEffect(() => {
        if(isPreparing){
            setAwaitDisplay('none')
        }   
    },[isPreparing])

    useEffect(() =>{
        if(bidMade){
            if (isCash){
                let value = (Number(stopwatchValue) * Number(inputValue)).toFixed(2)
                if (Number(stopwatchValue) === Number(cashValue)){
                    if(!isAuto){
                        noMorebid()
                    }
                    countBid(inputValue)
                    countBid(value)
                    orangecColorButton(moneyBid, stopwatchValue)
                    changeSum(moneyBid)

                    
                }else{
                    if (Number(value)> inputValue){
                        countBid(value)
                    }
                }
                
            }else{
                let value = (Number(stopwatchValue) * Number(inputValue)).toFixed(2)
                if (Number(value)> inputValue){
                    countBid(value)
                }
            }
            

            
        }
            
    },[stopwatchValue])

    useEffect(() => {
        if (isAuto){
            setText('LƏĞV ET')
            changeBorder('1px solid red')
            setBackColor('rgb(255, 0, 0)')
            setDisplayP('none')
            if(!isPreparing){
                setAwaitDisplay('block')
            }
            whatBid()
        }else{
            setText('MƏRC')
            countBid(inputValue)
            changeBorder('none')
            setBackColor('rgb(39, 159, 9')
            setDisplayP('block')
            setAwaitDisplay('none')
            noMorebid()
        }
    }, [isAuto])

    useEffect(() => {
        if(isPost && bidMade && backColor==='rgb(226, 135, 67)'){
            checkWin()
        }
    }, [isPost])
    
    function checkWin(){ /**Добавить измнениение тотал сумма */
        if(bid==1){
            changeBidding(preVal => {return  {first:inputValue, sec:preVal.sec}}) 
        }else{
            changeBidding(preVal => {return  {first:preVal.first, sec:inputValue}}) 
        }
        orangecColorButton()
    }

// -- Input functions, bid-chosing
    function handleChange(e) {
        setValue(e.target.value)
        
    }

    function BlurCheck(e){
        let value = Number(e.target.value)
        if(value <0.20){
            setValue('0.20')
            countBid('0.20')
        } else if (value > 700.00){
            setValue('700.00')
            countBid('700.00')
        }else{
            setValue(Number(e.target.value).toFixed(2))
            countBid(Number(e.target.value).toFixed(2))
        }
    }

    function ChoseBidNumber(e){
        setValue((Number(e.target.textContent)).toFixed(2))
        countBid((Number(e.target.textContent)).toFixed(2))
    }
// -- functions with button 
    function startButton(e) {
        
            if(!disabledButton_Auto && e.target==startButton.current){
                const color = window.getComputedStyle(e).getPropertyValue('background-color')
                if(color==='rgb(39, 159, 9)'){
                    greenColorButton()
                } else if (color==='rgb(226, 135, 67)'){
                    changeSum(`${moneyBid}`)
                    orangecColorButton(moneyBid, stopwatchValue)
                } else if (color ==='rgb(255, 0, 0)'){
                    redColorButton()
                }
            }
    
    }

    function greenColorButton(){
        setText('LƏĞV ET')
        changeBorder('1px solid red')
        setBackColor('rgb(255, 0, 0)')
        setDisplayP('none')

        if(!isPreparing){
            setAwaitDisplay('block')
        }

        whatBid()
    }

    function orangecColorButton(m, v){
        if (isAuto){
            setText('LƏĞV ET')
            changeBorder('1px solid red')
            setBackColor('rgb(255, 0, 0)')
            setDisplayP('none')
            setAwaitDisplay('block')

        }else{
            setText('MƏRC')
            countBid(inputValue)
            changeBorder('none')
            setBackColor('rgb(39, 159, 9')
            setDisplayP('block')
            setAwaitDisplay('none')
            noMorebid()
        }
        CauseMessageAlert(m,v)
    }

    function CauseMessageAlert(m,v){
        if(bid=='1'){
            setMesAlert(preVal =>{
                return {is:true, 1:{coef: v, money:m}, 
                2:{coef: preVal['2'].coef, money:preVal['2'].money}}
            })
        }else{
            setMesAlert(preVal =>{
                return {is:true, 2:{coef: v, money:m}, 
                1:{coef: preVal['1'].coef, money:preVal['1'].money}}
            })
        }
    }
    function redColorButton(){
        setText('MƏRC')
        changeBorder('none')
        setBackColor('rgb(39, 159, 9')
        setDisplayP('block')
        setAwaitDisplay('none')
        noMorebid()

        if(isAuto){
            setAuto(false)
        }
    }
// -- setting bid, next bid
    function whatBid(){
        if(isRound){
            setDisabled(true)
            makeNextBid(true)
        }else{
            setDisabled(true)
            makeBid(true)
        }
    }

    function noMorebid(){
        setDisabled(false)
        makeBid(false)
        makeNextBid(false)
    }

    function makeNextToRealBid(){
        if (nextBid){
            makeBid(true)
            makeNextBid(false)
        }
    }
    
    function changeSum(sum){
        fetch('/change_sum_total',{
            method: 'PUT',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken':fetching
            },
            body: JSON.stringify({amount: sum})
        })
        .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok, can not change sum');
            }
            return response.json();
          })
          .then((result) => {
            setTotalSum(result['new_sum']); 

          })
          .catch((error) => {
            console.error('Fetch errorcwith changing sum:', error);
          });
    }

// Increment and Decrement funciotns for InputValue
    function Increment(){
        if(Number(inputValue)+0.01 <= 700.00){
            setValue((Number(inputValue)+0.01).toFixed(2))
            countBid((Number(inputValue)+0.01).toFixed(2))
        }
    }
    function Decrement(){
        if(inputValue == 0.21 || Number(inputValue)-0.01 >= 0.20){
            setValue((Number(inputValue)-0.01).toFixed(2))
            countBid((Number(inputValue)-0.01).toFixed(2))
        }
    }

  return (
        <div className={cl.make_bids} >
                            <div className={[cl.bidding, 'item_making_bids'].join(' ')}>
                                <div className={cl.bid_input}>
                                    <button  disabled = {disabled} className={cl.btn_number} data-type="minus" data-bid="1" onClick={Decrement}>-</button>
                                    <input disabled = {disabled} type="number" step="0.01" min="0.20"  max="700.00" className={cl.float_input} value={inputValue}  onBlur={BlurCheck} onChange={handleChange}/>
                                    <button disabled = {disabled} className={cl.btn_number} data-type="plus" data-bid="1" onClick={Increment}>+</button>
                                </div>
                                
                                <div className={cl.chose_buttons} id="chose_buttons">
                                    <button disabled = {disabled} className={['chose_bid_number', cl.grid_item_number].join(' ')} onClick={ChoseBidNumber}>1</button>
                                    <button disabled = {disabled} className={['chose_bid_number', cl.grid_item_number].join(' ')} onClick={ChoseBidNumber}>2</button>
                                    <button disabled = {disabled} className={['chose_bid_number', cl.grid_item_number].join(' ')} onClick={ChoseBidNumber}>5</button>
                                    <button disabled = {disabled} className={['chose_bid_number', cl.grid_item_number].join(' ')} onClick={ChoseBidNumber}>10</button> 
                                </div>
                            </div>
                            <div  className={[cl.bid_commit, 'item_making_bids', 'startButton'].join(' ')} id="FirstStartButton" >
                                <p className={cl.await_round} style={{display: awaitRoundDisplay}}>Növbəti turu gözləyirik</p>
                                <div  className={cl.color} onClick={(e) => startButton(e.currentTarget)} style={{backgroundColor:backColor}}>
                                    <h3 className={cl.button_make_bid}>{text}</h3>
                                <p className={cl.how_much_bid} style={{fontSize: '15px', display: displayP, fontWeight:'300'}}><span className="bid_span fisrt_bid" style={{fontSize: '22px'}} >{moneyBid}  </span> AZN</p>
                                </div>
                                
                            </div>
        </div>
  )
}

export default MakeBidsButtonArea